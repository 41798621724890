<template>
  <body class="hold-transition sidebar-mini layout-navbar-fixed">
    <div class="wrapper">
      <Navbar></Navbar>
      <Sidebar></Sidebar>
      <div class="content-wrapper">
           <ContentHeader></ContentHeader>
        <section class="content">
          <div class="container-fluid">
            <router-view></router-view>
          </div>
        </section>
      </div>
      <Footer></Footer>
      <ControlSidebar></ControlSidebar>
    </div>
  </body>
</template>

<script>
import ContentHeader from "./dashboard/ContentHeader.vue"
import Navbar from "./dashboard/Navbar.vue";
import Sidebar from "./dashboard/Sidebar.vue";
import Footer from "./dashboard/Footer.vue";
import ControlSidebar from "./dashboard/ControlSidebar.vue"

export default {
  name: "App",
  components: {
    ContentHeader,
    Navbar,
    Sidebar,
    Footer,
    ControlSidebar,
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,400i,700&display=fallback");
.fa-bars {font-size: 1.5rem;}
</style>

