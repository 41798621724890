<template>
  <title>Пехорка</title>
  <h3 class="m-0">Пехорка</h3>
  <div class="card card-default" v-for="category in categories" :key="category.id">
    <div class="card-header bg-secondary " data-card-widget="collapse">
      <h3 class="card-title"><b>({{ category.products.length }}шт) {{ category.name }} - {{ category.Состав }}</b></h3><br><span class="text-dark"><b>Состав:</b>{{ category.Состав }}, <b>Длинна:</b> ~ {{ category.Длинна }}, <b>Вес:</b> {{ category.Вес }}, <b>Производитель:</b> {{ category.Производитель }}</span>
       <div class="card-tools ">
        <button type="button" class="btn btn-tool" data-card-widget="collapse"  >
          <i class="fas fa-plus"></i>
        </button>
      </div>
    </div>
    <div class="card-body" >
<div class="row ">
<div class="row row-cols-2 row-cols-md-5 g-6">
    <div class="col" v-for="product in category.products" :key="product.id">
      <div class="card">
        <ImageModal  :imageUrl="product.img" :imageUrlS="product.imgS" :imageAlt="product.description" />
        <div class="card-footer">
        <h5 class="card-title"><strong>{{ product.name }}</strong></h5>
      </div>
      </div>
    </div>
  <div>
</div>
</div>
</div>
</div>
</div>
  <a id="back-to-top" href="#" class="btn btn-primary back-to-top" role="button" aria-label="Scroll to top">
      <i class="fas fa-chevron-up"></i>
    </a>
</template>

<script >
import axios from 'axios';
import ImageModal from './ImageModal.vue';

export default {
  components: {
    ImageModal
  },
   data() {
    return {
      categories: [],
    }
  },
  mounted() {
    // Выполнение API-запроса для получения каталога и товаров
    this.fetchData();
  },
  methods: {
    async fetchData() {
      try {
        // Выполнение запроса на сервер для получения данных
        const response = await axios.get('https://taftinggan.ru/api/api/index.php?type=Pehorka');
        const data = response.data;
        // Преобразование полученных данных и сохранение их в состояние компонента
        this.categories = data.categories.map(category => {
          return {
            id: category.id,
            name: category.name,
            Состав: category.Состав,
            Длинна: category.Длинна,
            Вес: category.Вес,
            Производитель: category.Производитель,
            products: data.products.filter(product => product.categoryId === category.id),
          };
        });
      } catch (error) {
        console.error('Ошибка при выполнении API-запроса:', error);
      }
    }
  }
 } 
</script>

