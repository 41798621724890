<template>
  <title>Подбор пряжи по цвету</title>
  <h3 class="m-0">Подбор пряжи по цвету с картинки</h3>
  <span style="color:red">*</span><em>При клике на картику выбирается среднее значение цвета из расчета: 3 пикселя по радиусу от точки клика!</em>
  <br> <br>
  
  <div class="row">
    <div class="col-md-6">
    <input type="file" class="btn btn-as bg-secondary" @change="handleFileChange" accept="image/*"><br>

      <img class="imgg" ref="image" :src="imageUrl" v-if="imageUrl" @click="handleImageClick">
      <div v-if="showCircle" class="circle" :style="{ left: circlePosition.x1 + 'px', top: circlePosition.y1 + 'px' }"></div>

    <div v-if="isLoading2" style="margin: 50px;">
      <span style="width: 50px;height: 50px;font-size: 31px;" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
    Загружаем...
     </div>
  </div>
    <div class="col-md-6">
      <div class="flex-direction: row" >
        <div class="color-square " v-for="(color, index) in selectedPixelColors" :key="index" >
          <div :style="{ backgroundColor: color }" style="height:30px;border-bootom:1px solid;">
            <div class="close" :id="index" @click="deleteSecondData(index)">
            </div>
          </div>
          <div style="border-top: 1px solid #000;">
          <span style="color:#000000; height:100px; font-size: 0.7rem; font-weight: bold; ">{{rgbToHex(color)}}<br>{{color}}</span>
          </div>
        </div>
      </div>
      <br>
      
<span style="font-size: 14px;" v-if="selectedPixelColors.length > 0">*чем меньше 'порог', тем ближе подбирается цвет, max: 100, min: 10, оптимально 35-40</span>
<div class="row" v-if="selectedPixelColors.length > 0">
  <div class="col-md-4">
  <label for="porog">Порог
  <input    name="porog" type="number" class="form-control" v-model="porog" >
</label>
</div>

  <div class="col-md-8">
    <label>Выбрать название пряжи
    <select v-model="selectedBrands" class="custom-select">
      <option value="0" >Искать везде</option>
      <option v-for="brand in brands" :key="brand.id" :value="brand.id">{{ brand.Brand }}: {{ brand.brand }}</option>
    </select></label>
  </div>

  <div class="col-md-12">
      <button v-if="selectedPixelColors.length > 0" class="btn bg-secondary" @click="findSimilarColors">
        Подобрать пряжу
      </button>
      <div v-if="isLoading">
        <button class="btn btn-primary" type="button" disabled>
          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          Подбираем...
        </button>
      </div>
      </div>
    </div>
    </div>
  </div>

      <div class="row" v-if="similarColors.length > 0">
        <div class="color-square2  col-md-12" v-for="obj in similarColors" :key="obj.attr1">
          <div  v-for="subobj, index in obj.attr2" :key="subobj.attr3">
          <div v-if="index == 0" :rowspan="obj.attr2.length"  :style="{ backgroundColor: obj.attr1 }" style="height:30px;border-bootom:1px solid;">
            <span style="padding-left: 5px;" :class="{ 'text-white': isDark(obj.attr1), 'text-dark': !isDark(obj.attr1) }">{{obj.attr1}}</span>
          </div>
          <div class="row rgbpraja">
            <ImageModal  :imageUrl="subobj.attr3" :imageUrlS="subobj.attr5" :imageAlt="subobj.attr4" style="margin: 10px;cursor:pointer; " />
          </div>
          </div>
        </div>
      </div>
      <!-- <div class="row btn bg-warning" else-if>К сожалению ни чего не найдено, проверьте выбранные цвета и попробуйте снова</div> -->
<br>





</template>

<script>
import axios from 'axios';
import ImageModal from './ImageModal.vue';
export default {
  components: {
    ImageModal
  },
  
  data() {
    return {
      imageUrl: null,
      selectedPixelColors: [],
      similarColors: [], // массив для похожих цветов
      colorArray: [],
      porog: 40,
      brand: 0,
      brands: [],
      selectedBrands: 0,
      isLoading: false,
      isLoading2: false,
      showCircle: false,
      circlePosition: { x: 0, y: 0 }     
    };
  },
  mounted() {
    this.fetchBrands();
  },
  computed: {
      Porog() {return  Math.ceil(this.porog)}},
      // selectedBrands() {return this.selectedBrands.id},
      
   methods: {

//изменение цвета шрифта
isDark(color) {
    var r, g, b, hsp;
    if (String(color).match(/^rgb/)) {
      color = color.match(/^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/);
      r = color[1];
      g = color[2];
      b = color[3];
    } else {
      color = +("0x" + color.slice(1).replace(
        color.length < 5 && /./g, '$&$&'
      ));
      r = color >> 16;
      g = color >> 8 & 255;
      b = color & 255;
    }
    hsp = Math.sqrt(
      0.299 * (r * r) +
      0.587 * (g * g) +
      0.114 * (b * b)
    );
    return hsp < 127.5;
  },


    async fetchBrands() {
      try {
        // Выполнение запроса на сервер для получения данных
        const response = await axios.get('https://taftinggan.ru/api/api/categories.php');
        const data = response.data;
        // Преобразование полученных данных и сохранение их в состояние компонента
        this.brands = data.categories.map(category => {
          return {
            id: category.id,
            brand: category.name,
            Brand: category.BrandR,
          };
        });
      } catch (error) {
        console.error('Ошибка при выполнении API-запроса:', error);
      }
    },



    
    async findSimilarColors() {
      try {
        this.isLoading = true;
        const response = await axios.get('https://taftinggan.ru/api/api/ColorProducts.php', {params:{brand: this.selectedBrands  }});
        const colorArray = response.data; // Получить массив данных
        this.similarColors = []; // Очищаем массив перед началом поиска
        this.selectedPixelColors.forEach(color => {
        const colorObj = this.rgbToObj(color);
        if (Array.isArray(colorArray)) {
          
          colorArray.forEach(color2 => {
            if (this.compareColors(colorObj, this.rgbToObj(color2.rgb)) === 'Цвета похожи') {
              // Поиск цвета в массиве similarColors
              const existingColor = this.similarColors.find(item => item.attr1 === color);
              if (existingColor) {
                // Если цвет уже существует, добавить значение в массив attr3
                existingColor.attr2.push({attr0: color2.categoryId, attr3: color2.img , attr4: color2.description , attr5: color2.imgS });
              } else {
                // Если цвет не существует, добавить новый объект в similarColors
                this.similarColors.push({
                  
                  attr1: color,
                  attr2: [
                    { attr0: color2.categoryId,
                      attr3: color2.img,
                       attr4: color2.description, 
                  attr5: color2.imgS},
                  ]
                 
                },);
              }
            }
          });
        }
        else {
        console.error("colorArray is not an array");
        }
    });
  } catch(error) {
    console.log(error);
  }
  finally {
    this.isLoading = false; 
  }
},
      



    // переводим rgb в нужный формат
     rgbToObj(color) {
  // Извлекаем числовые значения из строки с цветом
  const values = color.match(/\d+/g);
    // Если значения не найдены, возвращаем null
  if (!values) {
    return null;
  }
    // Создаем объект с полями r, g и b и заполняем их значениями
  const obj = {
    r: parseInt(values[0]),
    g: parseInt(values[1]),
    b: parseInt(values[2])
  };
  
  // Возвращаем объект
  return obj;
},


 // Функция для перевода цвета из формата RGB в формат HVS
 rgbToHsv(r, g, b) {
      let h, s, v;
      r = r / 255;
      g = g / 255;
      b = b / 255;
      let max = Math.max(r, g, b);
      let min = Math.min(r, g, b);
      let delta = max - min;
      
      // Вычисляем значение (V)
      v = max;
      
      // Если максимальное значение (V) равно 0, то цвет черный и его насыщенность (S) равна 0
      if (max === 0) {
        s = 0;
      } else {
        // Иначе вычисляем насыщенность (S)
        s = delta / max;
      }
      
      // Вычисляем оттенок (H)
      if (delta === 0) {
        h = 0; // hue не имеет значния, если входные r, g, b одинаковые
      } else if (max === r) {
        h = (g - b) / delta % 6;
      } else if (max === g) {
        h = (b - r) / delta + 2;
      } else {
        h = (r - g) / delta + 4;
      }

      h = Math.round(h * 60);
      s = Math.round(s * 100);
      v = Math.round(v * 100);
      
      return { h, s, v };
    },
    
    // Функция для сравнения двух цветов на похожесть
   compareColors(color1, color2) {
      let hsvColor1 = this.rgbToHsv(color1.r, color1.g, color1.b);
      let hsvColor2 = this.rgbToHsv(color2.r, color2.g, color2.b);
      
      // Находим разницу между оттенками двух цветов (H)
      let deltaH = Math.abs(hsvColor1.h - hsvColor2.h);
      
      // Находим разницу между насыщенностью двух цветов (S)
      let deltaS = Math.abs(hsvColor1.s - hsvColor2.s);
      
      // Находим разницу между значениями двух цветов (V)
      let deltaV = Math.abs(hsvColor1.v - hsvColor2.v);
      
      // Вычисляем общую разницу на основе разниц в H, S и V
      let totalDelta = deltaH + deltaS + deltaV;
      
      // Если общая разница меньше 50, то цвета считаются похожими
      if (totalDelta < this.porog) {
        return 'Цвета похожи';
        // console.log('Цвета похожи');
      } else {
        return 'Цвета различаются';
        // console.log('Цвета различаются');
      }
    },
  
//преобразуем RGB в HEX
   rgbToHex(rgb) {
    const numbers = rgb.slice(4, -1).split(', ');
    // Преобразуем строки в числа и возвращаем массив чисел
    rgb = numbers.map(Number);
  // Получаем значения красного, зеленого и синего цветов
  const red = rgb[0];
  const green = rgb[1];
  const blue = rgb[2];

  // Переводим значения в шестнадцатеричную систему и добавляем ведущий ноль, если необходимо
  const redHex = red.toString(16).padStart(2, '0');
  const greenHex = green.toString(16).padStart(2, '0');
  const blueHex = blue.toString(16).padStart(2, '0');

  // Соединяем значения красного, зеленого и синего цветов в формате HEX
  const hex = `#${redHex}${greenHex}${blueHex}`;
 
  return hex;
  
},
// удаляем не нужный цвет из массива
    deleteSecondData(index) {
    this.selectedPixelColors.splice(index, 1);
    this.similarColors=[];
  },

    handleFileChange(event) {
      this.isLoading2 = true;
      const file = event.target.files[0];
      this.imageUrl = URL.createObjectURL(file);
      this.similarColors=[];
      this.selectedPixelColors = [];
      this.isLoading2 = false;
    },
    
    handleImageClick(event) {
    // Получаем координаты клика
    const x1 = event.clientX-5;
    const y1 = event.clientY-5;
    // Обновляем позицию круга
    this.circlePosition = { x1, y1 };
    // Показываем круг
     this.showCircle = true;
    setTimeout(() => {
      this.showCircle = false;
    }, "1000");

  const image = this.$refs.image;
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');

  const scale = 300 / image.naturalWidth;
  const width = image.naturalWidth * scale;
  const height = image.naturalHeight * scale;

  canvas.width = width;
  canvas.height = height;
  context.clearRect(0, 0, width, height);
  context.drawImage(image, 0, 0, width, height);

  const boundingRect = image.getBoundingClientRect();
  const x = event.clientX - boundingRect.left;
  const y = event.clientY - boundingRect.top;

  // const imageData = context.getImageData(x, y, 1, 1);
  // const pixelData = imageData.data;
  // const selectedColor = `rgb(${pixelData[0]}, ${pixelData[1]}, ${pixelData[2]})`;

  // Проверка наличия выбранного цвета в массиве
  // if (this.selectedPixelColors.includes(selectedColor)) {
  //   return; // Не выполнять функцию в случае повторного выбора цвета
  // }

  // Получение ближайших 5 пикселей и расчет среднего значения цвета
  const neighboringPixels = [];
  const pixelRadius = 3;

  for (let i = x - pixelRadius; i <= x + pixelRadius; i++) {
    for (let j = y - pixelRadius; j <= y + pixelRadius; j++) {
      const neighboringImageData = context.getImageData(i, j, 1, 1);
      const neighboringPixelData = neighboringImageData.data;
      neighboringPixels.push({
        r: neighboringPixelData[0],
        g: neighboringPixelData[1],
        b: neighboringPixelData[2]
      });
    }
  }

  const rSum = neighboringPixels.reduce((sum, pixel) => sum + pixel.r, 0);
  const gSum = neighboringPixels.reduce((sum, pixel) => sum + pixel.g, 0);
  const bSum = neighboringPixels.reduce((sum, pixel) => sum + pixel.b, 0);

  const rAverage = Math.round(rSum / neighboringPixels.length);
  const gAverage = Math.round(gSum / neighboringPixels.length);
  const bAverage = Math.round(bSum / neighboringPixels.length);

  const averageSelectedColor = `rgb(${rAverage}, ${gAverage}, ${bAverage})`;

  // Проверка наличия среднего цвета в массиве
  if (this.selectedPixelColors.includes(averageSelectedColor)) {
    return; // Не выполнять функцию в случае повторного выбора цвета
  }

  this.selectedPixelColors.push(averageSelectedColor);
  if (this.selectedPixelColors.length >= 9) {
    alert('Лимит выбора закончен, максимально можно выбрать только 9 цветов');
    return; // Прекратить выполнение функции при достижении лимита выбора
  }
}
  }
};



</script>

<style>
.rgbpraja {
  float: left;
  max-width: 100px;
  min-height: 115px;
}

.circle {
  position: fixed;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 2px solid red;
  pointer-events: none; /* чтобы клик проходил сквозь круг */
}

.btn-as {border-radius: 4px 4px 0px 0px !important;}

.selected-color {
  width: 100px;
  height: 100px;
  margin-bottom: 10px;
}

.color-list {
  display: flex;
  justify-content: space-between;
}

.color {
  width: 50px;
  height: 50px;
}

.imgg {
  max-width:297px;
  border: 1px solid #ddd;
  border-radius: 0px 0px 4px 4px !important;}

.color-square {
  margin-left:10px;
  width: 100px;
  height: 80px;
  border: 1px solid #ddd;
  margin-top: 10px;
  border-radius: 4px;
  text-align: center;
}

.color-square2 {
  border: 1px solid #ddd;
  margin-top: 10px;
  border-radius: 4px;
  padding-left: 0px !important;
  padding-right: 0px !important;
font-weight: bold;
}

.text-white {
  font-weight: normal;
}
.text-dark {
  font-weight: normal;
}

.close {
  float: right;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1;
    color: #000;
    cursor: pointer;
    background-color: #FFFFFF;
    height: 15px;
    width: 14px;
    border-radius: 5px;
}
.close:after {
  position: relative;
    content: "\d7";
    font-size: 25px;
    color: #000;
    font-weight: bold;
    top: -6px;
    left: 0px;
}

</style>