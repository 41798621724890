<template>
 <title>TaftingGan (калькулятор, пряжа)</title>
     <div class="card-body">
<div class="row ">
<div class="row row-cols-2 row-cols-md-4 g-5">
   <router-link to="/calculator" class="nav-link" active-class="active">
  <div class="col">
    <div class="card">
      <img src="https://taftinggan.ru/img/calc.webp" style="max-width: 800px;" class="card-img-top" alt="Калькулятор">
      <div class="card-footer">
        <h5 class="card-title"><strong class="ff">Калькулятор</strong></h5>
      </div>
    </div>
  </div>
   </router-link>

   <router-link to="/colorpicker" class="nav-link" active-class="active">
  <div class="col">
    <div class="card">
      <img src="https://taftinggan.ru/img/picker.webp" style="max-width: 800px;" class="card-img-top" alt="ColorPicker">
      <div class="card-footer ">
        <h5 class="card-title"><strong class="ff">ColorPicker</strong></h5>
      </div>
    </div>
  </div>
   </router-link>

   <router-link to="/yarns" class="nav-link" active-class="active">
  <div class="col">
    <div class="card">
      <img src="https://taftinggan.ru/img/yarns.webp" style="max-width: 800px;" class="card-img-top" alt="Пряжа">
      <div class="card-footer ">
        <h5 class="card-title"><strong class="ff">Пряжа</strong></h5>
      </div>
    </div>
  </div>
   </router-link>

   

</div>
</div>
</div>

<strong>Изменения, дополнения (снизу вверх):</strong><br>
ColorPicker: <em>Коррекция размера пикселей во круг взятой точки.</em><br>
Общее: <em>По маленьку готовлю переход на авторизацию с дальнейшим расширением.</em><br>
Общее: <em>Залил еще пряжу. </em><br>
<!-- <b>Но по факту, дело мутное, благодарностей нема, интереса кроме как - я пользователь, больше нету. Посему, варианта два, или разработка "умирает" как есть или я перевожу на платную основу - пока думаю.</b> -->
  Общее: <em>В общем вроде все как бы пока есть, кроме наборов пряжи :)) пора их заливать (но ручной труд тяжкий, посему, процесс жеский)</em><br>
  ColorPicker: <em>Маленько подкорректировал взятие пикселей, теперь берется среднее значение из ближайщих, так более корректно определяется цвет с картинки.</em><br>
  ColorPicker: <em>Допиливается по маленьку, добавляются фишечки. (отзывов нету, видимо все или плохо или хорошо) :))</em><br>
  <b style="color:red">ColorPicker: <em>В тестовом режиме запустил определение пряжи по цвету с картинки (жду отзывы) :))</em></b><br>
  <!-- Общее: <em>Поправлены не критичные баги. А вообще, пока не знаю, что еще суда можно запихнуть, однако в работе подбор пряжи по цвету. пс. саму пряжу заливать не хватает времени, к сожалению. :((<br>Посему жду ваших предложений в <a style="text-decoration: underline;" target="_blank" href="https://t.me/forestcar">telegram</a>.<br>---</em><br>
  Общее: <em>Исправлены баги верстки, добавлены некоторые фичи (спасибо пользователям за обратную связь)</em><br>
  Общее: <em>Долго не обновлял, пришлось переписать базу по пряже, первый заход был не очень удачный</em><br>
  Калькулятор: <em>Исправлены баги в подсчетах при первом запуске калькулятора (не критично)</em><br>
  Калькулятор: <em>Добавлен расчет количества мотков</em><br>
  Общее: <em>Изменил не много структуру сайта, видимо для большего удобства :).</em><br>
  Калькулятор: <em>Добавлено сохранение полей после ввода, кроме размера ковра и чека по срочности.</em><br>
  Калькулятор: <em>Исправлен подсчет (добавлен расчет дополнительных трат)</em><br>
  Пряжа: <em>Наполнение раздела -пряжа</em> -->
  <br>

  
  Если вы обнаружили ошибку или есть предложение по расширению, улучшению, прошу обращаться в <a style="text-decoration: underline;" target="_blank" href="https://t.me/forestcar">telegram</a>.
        <br>
  <a style="text-decoration: underline;" href="https://www.tinkoff.ru/cf/7Rwn12pqPOX" target="_blank">
          Если вам понравилась разработка, не откажусь от жертвенной копеечки. :))
        </a> <br> <br>
 
</template>

<script>
export default {

}
</script>

<style>
@media (max-width: 576px) { 
   .ff {font-size: 1rem;}
 }
</style>