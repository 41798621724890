<template>
  <nav class="main-header navbar navbar-expand navbar-white navbar-light">
    <ul class="navbar-nav">
      <li class="nav-item">
        <a class="nav-link" data-widget="pushmenu" href="#" role="button">
          <i class="fas fa-bars"></i>
        </a>
      </li>
    </ul>
    <ul class="navbar-nav ml-auto">
      <li class="nav-item dropdown">
        <a class="nav-link"  href="https://www.tinkoff.ru/cf/7Rwn12pqPOX" target="_blank">Благодарность</a>
      </li>
      <!-- <li class="nav-item dropdown">
        <a class="nav-link" data-toggle="dropdown" href="#">Пряжа</a>
        <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right">
          <router-link to="/pehorka" class="nav-link">Пехорка</router-link>
          <div class="dropdown-divider"></div>
          <router-link to="/alize" class="nav-link">Alize</router-link>
        </div>
      </li> -->
    </ul>
  </nav>
</template>

<script>
export default {

}
</script>

<style>

</style>