<template>
<noindex>
  <table class="table table-bordered">
    <thead>
      <tr>
        <th>ID</th>
        <th>Бренд</th>
        <th>БрендR</th>
        <th>Название</th>
        <th>Состав</th>
        <th>Длинна</th>
        <th>Вес</th>
        <th>Производитель</th>
        <th>Сорт.</th>
        <th>Блок</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="item in categories" :key="item.id">
        <td>{{ item.id }}</td>
        <td>{{ item.Brand }}</td>
        <td>{{ item.BrandR }}</td>
        <td>{{ item.name }}</td>
        <td>{{ item.sostav }}</td>
        <td>{{ item.dlinna }}</td>
        <td>{{ item.ves }}</td>
        <td>{{ item.firms }}</td>
        <td><input v-model="item.sort" @input="saveData"></td>
        <td><input v-model="item.block" @input="saveBlock"></td>
        <td>
          <button @click="editItem(item.id)">Edit</button>
          <button @click="deleteItem(item.id)">Delete</button>
          <button @click="blockItem(item.id)">Block</button>
          <input type="checkbox" name="table[]" v-model="checked">
        </td>
      </tr>
    </tbody>
  </table>

</noindex>
 </template>
 
 <script>
 import axios from 'axios';

 export default {
  data() {
    return {
      categories: [],
      checked: false
    }
  },


   mounted() {
    // Выполнение API-запроса для получения каталога 
    this.fetchData();
  },


   methods: {

    saveData() {
      // Отправка данных в базу данных
        axios.post('/api/save', { name: this.name })
        .then(response => {
          console.log(response.data);
        })
        .catch(error => {
          console.error(error);
        });
    },


    async fetchData() {
      try {
        // Выполнение запроса на сервер для получения данных
        const response = await axios.get('https://taftinggan.ru/api/api/catalog.php');
        const data = response.data;
        // Преобразование полученных данных и сохранение их в состояние компонента
        this.categories = data.categories.map(categories => {
          return {
            id: categories.id,
            Brand: categories.Brand,
            BrandR: categories.BrandR,
            name: categories.name,
            isBlockeds: categories.block,
            sostav: categories.Состав,
            dlinna: categories.Длинна,
            ves: categories.Вес,
            firms: categories.Производитель,
            sort: categories.sort,
          }; 
        });
      } catch (error) {
        console.error('Ошибка при выполнении API-запроса:', error);
      }
    },
   
    editItem(id) {
      // Реализация редактирования элемента
      console.log(id);
    },
    deleteItem(id) {
      // Реализация удаления элемента
      console.log(id);
    },
    blockItem(Id) {
      // axios.get('https://taftinggan.ru/api/api/catalog.php' + Id, { block: isBlockeds ? 1 : 0 })
          console.log(Id);
    },
   }
 };
 </script>