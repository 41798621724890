<template>
  <footer class="main-footer">
    <strong>Copyright &copy; 2023-{{ formatDate() }} TaftingGAN.</strong>
    All rights reserved.
  </footer>
</template>

<script>
import dayjs from 'dayjs';
export default {
  methods: {
        formatDate(dateString) {
            const date = dayjs(dateString);
                // Then specify how you want your dates to be formatted
            return date.format('YYYY');
        }
    }
}
</script>

<style>

</style>
