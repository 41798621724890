<template>
 <title>Калькулятор</title>
  <h3 class="m-0">Калькулятор по коврам (бесплатный)</h3>
 
<br>
  <div class="card card-default">
    <div class="card-header bg-secondary" data-card-widget="collapse">
      <h3 class="card-title">Вводные</h3>
       <div class="card-tools">
        <button type="button" class="btn btn-tool" data-card-widget="collapse">
          <i class="fas fa-minus"></i>
        </button>
      </div>
    </div>
    <!-- /.card-header -->
    <div class="card-body">
      <div class="row ">
        <div class="col-md-4">
          <div class="input-group ">
            <label class="form-label">Количество мотков в 1м² (примерно)</label>
            <div class="input-group-prepend">
              <button class="btn btn-outline-secondary dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Расчёт количества мотков</button>
              <div class="dropdown-menu" style="padding: 10px;">
                Расчет количества мотков на 1м²
                <label class="form-label">Длина стежка (сантиметры)<em class="small"> (вынуть стежок пряжи из ткани и померить длину)</em></label>
                <input type="number" class="form-control form-control-sm btn-group" role="group" v-model="dlinastej" @input="checkValue">
                <label class="form-label">Длина мотка, метров</label>
                <input type="number" class="form-control form-control-sm  btn-group" role="group" v-model="dlinnamotka" @input="checkValue">
                <label class="form-label">Количество нитей<em class="small"> (Во сколько нитей бьёте)</em></label>
                <input type="number" class="form-control form-control-sm btn-group" role="group" v-model="colniti" @input="checkValue">
                <label class="form-label">Плотность<em class="small"> (1-плотно, 2-через клетку)</em></label>
                <input type="number" class="form-control form-control-sm btn-group" role="group" v-model="plotnost" @input="checkValue">
                <div>
              <strong>ИТОГО примерно: </strong><strong style="color:red"> {{ total2 }} шт. </strong>(округляем)<br>
              <em>Накидываем 0.5 см. на брак ~{{ total3 }} шт.</em>
              </div>
              </div>
            </div>
          <input type="number" class="form-control btn-group" role="group" v-model="motki" @input="checkValue">
</div>
          <label class="form-label">Средняя цена за 1 моток (&#x20bd;)</label>
          <input type="number" class="form-control" v-model="cenamotok" @input="checkValue">
         
        </div>
        <div class="col-md-4">
          <label class="form-label">Количество дней на изготовление</label>
          <input type="number" class="form-control" v-model="dney" @input="checkValue">
        
          <label class="form-label">Цена аренды + коммун. (за день, &#x20bd;)</label>
          <input type="number" class="form-control" v-model="cenadney"  @input="checkValue">
        </div>
      </div>
    </div>
</div>

<div class="card card-default collapsed-card">
    <div class="card-header bg-secondary " data-card-widget="collapse">
      <h3 class="card-title">Материалы на 1м²</h3>
       <div class="card-tools ">
        <button type="button" class="btn btn-tool" data-card-widget="collapse"  >
          <i class="fas fa-plus"></i>
        </button>
       
      </div>
    </div>
    <!-- /.card-header -->
    <div class="card-body">

<div class="row ">
  <div class="col-md-1">
    <label class="form-label">Ткань(&#x20bd;)</label>
    <input type="number" class="form-control" v-model="tkan" @input="checkValue">
  </div>
  <div class="col-md-2">
    <label class="form-label">Гидроиз.(&#x20bd;)</label>
    <input type="number" class="form-control" v-model="gidro" @input="checkValue">
  </div>
  <div class="col-md-3">
    <label class="form-label">Задник(подложка) (&#x20bd;)</label>
    <input type="number" class="form-control" v-model="podkladka" @input="checkValue">
  </div>
  <div class="col-md-3">
    <label class="form-label">Кип. лента (&#x20bd; за метр)</label>
    <input type="number" class="form-control" v-model="kipernay" @input="checkValue"> 
  </div>
  <div class="col-md-3">
    <label class="form-label">Доп.(клей, термо)(&#x20bd;)</label>
    <input type="number" class="form-control" v-model="dopkley" @input="checkValue">
  </div>
</div>

</div></div>
<div class="card card-default collapsed-card">
    <div class="card-header bg-secondary" data-card-widget="collapse">
      <h3 class="card-title">Работы</h3>
       <div class="card-tools">
        <button type="button" class="btn btn-tool" data-card-widget="collapse">
          <i class="fas fa-plus"></i>
        </button>
        
      </div>
    </div>
    <!-- /.card-header -->
    <div class="card-body">
<div class="row ">
  <div class="col-md-3">
    <label class="form-label">Подготовка макета (&#x20bd;)</label>
    <input type="number" class="form-control" v-model="maket" @input="checkValue">
  </div>
  <div class="col-md-2">
    <label class="form-label">Контуринг (&#x20bd;)</label>
    <input type="number" class="form-control" v-model="maketcontur" @input="checkValue">
  </div>
  <div class="col-md-3">
    <div class="form-check">
      
      <input class="form-check-input" type="radio" v-model="check2" value="rabota1v" name="flexRadioDefault" >
      <label class="form-label" >Цена работ (&#x20bd;)</label>
      <input type="number" class="form-control" v-model="rabota1" @input="checkValue">
    </div>
    </div>
    <div class="col-md-2">
    <div class="form-check">
      <input class="form-check-input" type="radio" v-model="check2" value="rabota2v" name="flexRadioDefault" checked>
      <label class="form-label">Цена работ (%)</label>
      <input type="number" class="form-control" v-model="rabota2" @input="checkValue">
    </div>
  </div>
  <div class="col-md-2 ">
      <label class="form-label" >Срочно (%)&nbsp;-&nbsp; </label>
      <input type="checkbox" v-model="check">
      <input type="number" class="form-control" v-model="srochnoct" @input="checkValue">
    </div>
</div>

</div></div>
<div class="card card-default collapsed-card">
    <div class="card-header bg-secondary" data-card-widget="collapse">
      <h3 class="card-title">Размеры изделия</h3>
       <div class="card-tools">
        <button type="button" class="btn btn-tool" data-card-widget="collapse">
          <i class="fas fa-plus"></i>
        </button>
        
      </div>
    </div>
    <!-- /.card-header -->
    <div class="card-body">
  <div class="row">
    <div class="col-md-2">
      <label class="form-label">Ширина (см)</label>
      <input type="number" class="form-control" v-model="shirina" @input="checkValue">
    </div>
    <div class="col-md-2">
      <label class="form-label">Высота (см)</label>
      <input type="number" class="form-control" v-model="visota" @input="checkValue">
    </div>
    
  </div>
  

      </div>
  
 </div>
 <div class="row">
    <div class="col-md-5">
    <h4 style="color:red"><strong>ИТОГО: {{ Math.round(total) }} &#x20bd;</strong></h4>
     </div>
  </div>
<div class="row">
  <div class="col-md-3">
    Пряжа:  {{ Pryja }} &#x20bd; (~{{ Pryja2 }} шт.)<br>
    Ткань: {{ Tkan }} &#x20bd;<br>
    Гидра: {{ Gidra }} &#x20bd;<br>

  </div>
  <div class="col-md-3">
    Подкладка: {{ Podkladka }} &#x20bd;<br>
    Киперная лента: {{ kipernay_lenta_cena }} &#x20bd; (~{{ kipernay_lenta_m }} м.)<br>
    Доп: {{ Dop }} &#x20bd;
  </div>
  </div>
  <div class="row">
    <div class="col-md-5">
  <b>Итого ~ по материалам: {{ this.Pryja+this.Tkan+this.Gidra+this.Podkladka+this.kipernay_lenta_cena+this.Dop }} &#x20bd;</b><br>
</div>
</div>

<div class="row">
    <div class="col-md-5">
  Остаток на руках:<br>
  -- итого минус материалы-аренда: ~<b>{{ Math.round((total)-(this.Pryja+this.Tkan+this.Gidra+this.Podkladka+this.kipernay_lenta_cena+this.Dop))-Arenda }} &#x20bd;</b><br>
  -- итого минус 4%-материалы-аренда: ~<b>{{ Math.round((total-(total*4/100))-(this.Pryja+this.Tkan+this.Gidra+this.Podkladka+this.kipernay_lenta_cena+this.Dop))-Arenda }} &#x20bd;</b><br>
  -- итого минус 6%-материалы-аренда: ~<b>{{ Math.round((total-(total*6/100))-(this.Pryja+this.Tkan+this.Gidra+this.Podkladka+this.kipernay_lenta_cena+this.Dop))-Arenda }} &#x20bd;</b><br><br>
</div>
</div>

  <div class="alert alert-info alert-dismissible">
    <h5 style="color:black"><i class="icon fas fa-info"></i> Внимание!</h5>
     Если вы обнаружили ошибку или есть предложение по расширению, улучшению, прошу обращаться в <a target="_blank" href="https://t.me/forestcar">telegram</a>.
  </div>
                        
</template>



    
    <script>
   
export default {
  
  data() {
    return {
      dney: 2,
      motki: 40,
      cenadney: 500,
      cenamotok: 94,
      visota: 100,
      shirina: 100,
      srochnoct: 50,
      check: false,
      maket: 500,
      maketcontur: 2500,
      podkladka: 500,
      gidro: 250,
      tkan: 400,
      kipernay: 80,
      rabota1: 4000,
      rabota2: 40,
      check2:'',
      dopkley: 120,
      dlinastej: 3,
      colniti: 3,
      plotnost: 2,
      dlinnamotka: 200,
      value: ''
    }
    
    
  },
  
  mounted() {
    if (localStorage.dney) {
      this.dney = localStorage.dney;
    }
    if (localStorage.motki) {
      this.motki = localStorage.motki;
    }
    if (localStorage.cenadney) {
      this.cenadney = localStorage.cenadney;
    }
    if (localStorage.cenamotok) {
      this.cenamotok = localStorage.cenamotok;
    }
    if (localStorage.srochnoct) {
      this.srochnoct = localStorage.srochnoct;
    }
    
    if (localStorage.maket) {
      this.maket = localStorage.maket;
    }
    if (localStorage.maketcontur) {
      this.maketcontur = localStorage.maketcontur;
    }
    if (localStorage.podkladka) {
      this.podkladka = localStorage.podkladka;
    }
    if (localStorage.gidro) {
      this.gidro = localStorage.gidro;
    }
    if (localStorage.tkan) {
      this.tkan = localStorage.tkan;
    }
    if (localStorage.kipernay) {
      this.kipernay = localStorage.kipernay;
    }
    if (localStorage.rabota1) {
      this.rabota1 = localStorage.rabota1;
    }
    if (localStorage.rabota2) {
      this.rabota2 = localStorage.rabota2;
    }
    if (localStorage.check2) {
      this.check2 = localStorage.check2;
    }
    if (localStorage.dopkley) {
      this.dopkley = localStorage.dopkley;
    }

    if (localStorage.dlinastej) {
      this.dlinastej = localStorage.dlinastej;
    }
    if (localStorage.plotnost) {
      this.plotnost = localStorage.plotnost;
    }
    if (localStorage.colniti) {
      this.colniti = localStorage.colniti;
    }
    if (localStorage.dlinnamotka) {
      this.dlinnamotka = localStorage.dlinnamotka;
    }
  },
  watch: {
    dney(newdney) {
      localStorage.dney = newdney;
    },
    motki(newmotki) {
      localStorage.motki = newmotki;
    },
    cenadney(newcenadney) {
      localStorage.cenadney = newcenadney;
    },
    cenamotok(newcenamotok) {
      localStorage.cenamotok = newcenamotok;
    },
    srochnoct(newsrochnoct) {
      localStorage.srochnoct = newsrochnoct;
    },
    
    maket(newmaket) {
      localStorage.maket = newmaket;
    },
    maketcontur(newmaketcontur) {
      localStorage.maketcontur = newmaketcontur;
    },
    podkladka(newpodkladka) {
      localStorage.podkladka = newpodkladka;
    },
    gidro(newgidro) {
      localStorage.gidro = newgidro;
    },
    tkan(newtkan) {
      localStorage.tkan = newtkan;
    },
    kipernay(newkipernay) {
      localStorage.kipernay = newkipernay;
    },
    rabota1(newrabota1) {
      localStorage.rabota1 = newrabota1;
    },
    rabota2(newrabota2) {
      localStorage.rabota2 = newrabota2;
    },
    check2(newcheck2) {
      localStorage.check2 = newcheck2;
    },
    dopkley(newdopkley) {
      localStorage.dopkley = newdopkley;
    },
    dlinastej(newdlinastej) {
      localStorage.dlinastej = newdlinastej;
    },
    plotnost(newplotnost) {
      localStorage.plotnost = newplotnost;
    },
    colniti(newcolniti) {
      localStorage.colniti = newcolniti;
    },
    dlinnamotka(newdlinnamotka) {
      localStorage.dlinnamotka = newdlinnamotka;
    },
  },

  methods: {
    checkValue() {
      if (this.dney !== 0 && !this.dney) this.dney = 0
      if (this.motki !== 0 && !this.motki) this.motki = 0
      if (this.cenadney !== 0 && !this.cenadney) this.cenadney = 0
      if (this.cenamotok !== 0 && !this.cenamotok) this.cenamotok = 0
      // if (this.visota !== 0 && !this.visota) this.visota = 0
      // if (this.shirina !== 0 && !this.shirina) this.shirina = 0
      if (this.srochnoct !== 0 && !this.srochnoct) this.srochnoct = 0
      if (this.maket !== 0 && !this.maket) this.maket = 0
      if (this.maketcontur !== 0 && !this.maketcontur) this.maketcontur = 0
      if (this.podkladka !== 0 && !this.podkladka) this.podkladka = 0
      if (this.gidro !== 0 && !this.gidro) this.gidro = 0
      if (this.tkan !== 0 && !this.tkan) this.tkan = 0
      if (this.kipernay !== 0 && !this.kipernay) this.kipernay = 0
      if (this.rabota1 !== 0 && !this.rabota1) this.rabota1 = 0
      if (this.rabota2 !== 0 && !this.rabota2) this.rabota2 = 0
      if (this.dopkley !== 0 && !this.dopkley) this.dopkley = 0
      if (this.dlinastej !== 0 && !this.dlinastej) this.dlinastej = 0
      if (this.colniti !== 0 && !this.colniti) this.colniti = 0
      if (this.plotnost !== 0 && !this.plotnost) this.plotnost = 0
      if (this.dlinnamotka !== 0 && !this.dlinnamotka) this.dlinnamotka = 0

    }
  },


  computed: {


    //Расчитываем цену на пряжи
    Pryja() {
      return  Math.ceil(((parseInt(this.motki) /10000) * parseInt(this.cenamotok)*(parseInt(this.visota)*parseInt(this.shirina))))
  },
  Pryja2() {
      return  Math.round((Math.ceil(((parseInt(this.motki) /10000) * parseInt(this.cenamotok)*(parseInt(this.visota)*parseInt(this.shirina)))))/this.cenamotok)
  },
    //Расчитываем цену на ткань
    Tkan() {
      return  Math.ceil(((parseInt(this.tkan) /10000) *(parseInt(this.visota)*parseInt(this.shirina))))
  },  
    //Расчитываем цену на гидроизоляцию
    Gidra() {
      return  Math.ceil(((parseInt(this.gidro) /10000) *(parseInt(this.visota)*parseInt(this.shirina))))
  },
     //Расчитываем цену на подкладку
     Podkladka() {
      return  Math.ceil(((parseInt(this.podkladka) /10000) *(parseInt(this.visota)*parseInt(this.shirina))))
  },
    //Расчитываем цену на киперную ленту
    kipernay_lenta_cena() {
      return  Math.ceil(((parseInt(this.visota)+parseInt(this.shirina))*2)*(parseInt(this.kipernay)/100))
  },
    kipernay_lenta_m() {
      return  Math.round(((((parseInt(this.visota)+parseInt(this.shirina))*2)*(parseInt(this.kipernay)/100)))/this.kipernay)
  },
    //Расчитываем цену на времени изготовления
    Arenda() {
      return  Math.ceil((parseInt(this.dney)*parseInt(this.cenadney)))
  },
      //Расчитываем цену дополнительное 
      Dop() {
      return  Math.ceil(((parseInt(this.dopkley) /10000) *(parseInt(this.visota)*parseInt(this.shirina))))
  },
    //Расчитываем цену подготовка макета и контуринг
    MaketKontur() {
      return  Math.ceil((parseInt(this.maketcontur)+parseInt(this.maket)))
  },

top_rubli (){ return this.Pryja+this.Tkan+this.Gidra+this.Podkladka+this.kipernay_lenta_cena+this.Arenda+this.MaketKontur+this.Dop+parseInt(this.rabota1)},
top_sroch (){ return (((this.Pryja+this.Tkan+this.Gidra+this.Podkladka+this.kipernay_lenta_cena+this.Arenda+this.MaketKontur+this.Dop+parseInt(this.rabota1))/100)*(this.srochnoct))+(this.Pryja+this.Tkan+this.Gidra+this.Podkladka+this.kipernay_lenta_cena+this.Arenda+this.MaketKontur+this.Dop+parseInt(this.rabota1))},
top_proc (){ return ((((((this.Pryja+this.Tkan+this.Gidra+this.Podkladka+this.kipernay_lenta_cena+this.Arenda+this.MaketKontur+this.Dop)/100)*(parseInt(this.rabota2)))))+(((((this.Pryja+this.Tkan+this.Gidra+this.Podkladka+this.kipernay_lenta_cena+this.Arenda+this.MaketKontur+this.Dop))))))},
top_proc_sroch(){ return ((((((this.Pryja+this.Tkan+this.Gidra+this.Podkladka+this.kipernay_lenta_cena+this.Arenda+this.MaketKontur+this.Dop)/100)*(parseInt(this.rabota2)))+(this.Pryja+this.Tkan+this.Gidra+this.Podkladka+this.kipernay_lenta_cena+this.Arenda+this.MaketKontur+this.Dop))/100)*(this.srochnoct))+((((this.Pryja+this.Tkan+this.Gidra+this.Podkladka+this.kipernay_lenta_cena+this.Arenda+this.MaketKontur+this.Dop)/100)*(parseInt(this.rabota2)))+(this.Pryja+this.Tkan+this.Gidra+this.Podkladka+this.kipernay_lenta_cena+this.Arenda+this.MaketKontur+this.Dop))},


    total () {
        if (this.check2==='rabota1v') {
          if (this.check===false) { return Math.ceil(this.top_rubli) } else { return Math.ceil(this.top_sroch) } 
          } else {
          if (this.check===false) { return Math.ceil(this.top_proc) } else { return Math.ceil(this.top_proc_sroch) } 
                }
    },
    total2 () {
          if (this.plotnost===1){ 
            return  Math.ceil((((((parseInt(this.dlinastej)*parseInt(this.colniti))*12)*10000)/parseInt(this.dlinnamotka)))/100)
          } 
          else 
          {
            return Math.ceil((((((parseInt(this.dlinastej)*parseInt(this.colniti))*6)*10000)/parseInt(this.dlinnamotka)))/100)
          }
    },
    total3 () {
          if (this.plotnost===1){ 
            return  Math.ceil(((((((parseInt(this.dlinastej)+0.5)*parseInt(this.colniti))*12)*10000)/parseInt(this.dlinnamotka)))/100)
          } 
          else 
          {
            return Math.ceil(((((((parseInt(this.dlinastej)+0.5)*parseInt(this.colniti))*6)*10000)/parseInt(this.dlinnamotka)))/100)
          }
    },

  }
}

</script>

<style scoped>
</style>

