import { createWebHistory, createRouter } from "vue-router";
import Start from "@/components/Start.vue";
import Calculator from "@/components/Calculator.vue";
import Yarns from "@/components/Yarns.vue";
// import colorpicker from "@/components/ColorPicker.vue";
import pehorka from "@/components/Pehorka.vue";
import alize from "@/components/Alize.vue";
import UploadProduct from "@/components/UploadProduct.vue";
 import Colors from "@/components/ColorPicker.vue";
// import Colors2 from "@/components/Color2.vue";
import NotFoundView from '@/dashboard/NotFoundView.vue';



const routes = [
  {
    path: "/",
    name: "Start",
    component: Start,
  },
  // {
  //   path: "/colorpicker",
  //   name: "colorpicker",
  //   component: colorpicker,
  // },
  {
    path: "/ColorPicker",
    name: "ColorPicker",
    component: Colors,
  },

  // {
  //   path: "/color2",
  //   name: "Colors2",
  //   component: Colors2,
  // },
  {
    path: "/calculator",
    name: "Calculator",
    component: Calculator,
  },
  {
    path: "/yarns",
    name: "Yarns",
    component: Yarns,
  },
  {
   path: "/pehorka",
   name: "pehorka",
   component: pehorka,
 },
 {
  path: "/alize",
  name: "alize",
  component: alize,
},
{
  path: "/UploadProduct",
  name: "UploadProduct",
  component: UploadProduct,
},
 {
  path: '/:pathMatch(.*)*',
  name: '404',
  component: NotFoundView
},

];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;