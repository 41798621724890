<template>
  <aside class="main-sidebar sidebar-dark-primary elevation-4 ">
    <router-link to="/" class="brand-link">
      <img src="../assets/img/taftinggan.png" alt="TaftingGAN" class="brand-image img-circle elevation-3" style="opacity: .8">
      <span class="brand-text font-weight-light">TaftingGAN</span>
    </router-link>
    <div class="sidebar">
        <nav class="mt-2">
        <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
            <li class="nav-item">
            <router-link to="/calculator" class="nav-link" active-class="active">
              <i class="nav-icon fas fa-th"></i>
              <p>
                Калькулятор
              </p>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link to="/colorpicker" class="nav-link" active-class="active">
              <i class="nav-icon fas fa-picture"></i>
              <p>
                ColorPicker
              </p>
            </router-link>
          </li>         
          <li class="nav-item">
            <a href="#" class="nav-link">
              <i class="nav-icon fab fa-slack"></i>
              <p>Пряжа<i class="fas fa-angle-left right"></i></p>
            </a>
            <ul class="nav nav-treeview">
              <li class="nav-item">
                <router-link to="/pehorka" class="nav-link" active-class="active">
                  <i class="far fa-circle nav-icon"></i>
                  <p>Пехорка</p>
                </router-link>
              </li>
              <li class="nav-item">
                <router-link to="/alize" class="nav-link" active-class="active">
                  <i class="far fa-circle nav-icon"></i>
                  <p>Alize</p>
                </router-link>
              </li>
            </ul>
          </li>
        </ul>
      </nav>
    </div>
  </aside>
</template>

<script>
export default {

}
</script>

<style>

</style>