<template>
   <title>Пряжа</title>
   <div class="row ">
<div class="row row-cols-2 row-cols-md-5 g-6">
   <router-link to="/alize" class="nav-link" active-class="active">
  <div class="col" >
    <div class="card  ">
      <img src="Https://taftinggan.ru/img/alize/logo.png" class="card-img-top " >
      <div class="card-footer">
        <h5 class="card-title"><strong>Alize</strong></h5>
      </div>
    </div>
  </div>
   </router-link>

   <router-link to="/pehorka" class="nav-link" active-class="active">
  <div class="col">
    <div class="card">
      <img src="Https://taftinggan.ru/img/pehorka/logo.webp" class="card-img-top" >
      <div class="card-footer">
        <h5 class="card-title"><strong>Пехорка</strong></h5>
      </div>
    </div>
  </div>
   </router-link>
</div>

</div>


</template>

<script>
export default {

}
</script>

<style>

</style>