<template>
   <div>
     <img :src="imageUrl"  :alt="imageAlt" @click="openModal"  class="card-img-top">
     <div class="modal" tabindex="-1" style="display: flex; justify-content: center; align-items: center;" v-if="showModal" >
       <div class="modal-dialog" >
         <div class="modal-content">
           <div class="modal-header">
             <h5 class="modal-title"><string>{{ imageAlt }}</string></h5>
           </div>
           <div class="modal-body">
             <img :src="imageUrlS" :alt="imageAlt" style="max-width: 100%;" >
           </div>
           <div class="modal-footer">
           <button type="button" class="btn btn-primary" @click="closeModal">Закрыть</button>
         </div>
         </div>
       </div>
     </div>
   </div>
 </template>
 
 <script>
 export default {
   name: 'ImageModal',
   props: {
     imageUrl: String,
     imageUrlS: String,
     imageAlt: String
   },
   data() {
     return {
       showModal: false
     }
   },
   methods: {
     openModal() {
       this.showModal = true;
     },
     closeModal() {
       this.showModal = false;
     }
   }
 }
 </script>
 
 <style scoped>
 .modal {
   position: fixed;
   z-index: 1050;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   overflow: auto;
   background-color: rgba(0, 0, 0, 0.5);
 }
 
 .modal-dialog {
   position: relative;
   width: auto;
   margin: 0.5rem;
   pointer-events: none;
 }
 
 .modal-content {
   position: relative;
   display: flex;
   flex-direction: column;
   pointer-events: auto;
   background-color: #fff;
   background-clip: padding-box;
   border: 1px solid #333;
   border-radius: 0.3rem;
   outline: 0;
 }
 </style>